import { Header } from "./Header";
import TableRows from "./TableRows";
import { Pagination } from "../Pagination";
import { Dropdown } from "../Dropdown";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import MobileTableRows from "./MobileTableRows";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const StyledTable = styled(Table)(({ theme }) => ({
  ".css-1ex1afd-MuiTableCell-root": {
    fontFamily: [
      "Avenir",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

const TableList = ({
  rawData,
  tableFields,
  checkedChange,
  currentItems,
  isCheck,
  handleClick,
  handleRemove,
  isCheckAll,
  handleSelectAll,
  data,
  handlePageClick,
  handleRemoveChecked,
  remove,
  handleSelectAllItems,
  itemsRange,
  pageCount,
  items,
  setValue,
  setSortingBy,
  sortTableData,
  setCurrentItems,
  isMouseOverScroll,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //check screen size
  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  // Set shadow for left size first name
  const handleEnter = () => {
    setIsMouseOver(true);
  };

  // Remove shadow for left size first name
  const handleLeave = () => {
    setIsMouseOver(false);
  };

  return (
    <StyledTable className="table-ui">
      {screenWidth > 576 && (
        <>
          <Header
            tableFields={tableFields}
            isChecked={isCheckAll}
            checkedChange={checkedChange}
            handleSelectAll={handleSelectAll}
            sortTableData={sortTableData}
            setSortingBy={setSortingBy}
            setCurrentItems={setCurrentItems}
            isMouseOver={isMouseOver}
            isMouseOverScroll={isMouseOverScroll}
          />
          <TableBody onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
            {(currentItems.length ? currentItems : sortTableData)?.map(
              (row, index) => (
                <TableRows
                  rawData={rawData}
                  info={row}
                  key={row.taskId}
                  index={index}
                  id={isCheckAll ? index : row.taskId}
                  isMouseOver={isMouseOver}
                  isMouseOverScroll={isMouseOverScroll}
                  keyRow={row}
                  isCheck={isCheck}
                  isCheckAll={isCheckAll}
                  handleClick={handleClick}
                  handleRemove={handleRemove}
                  isOddNumber={index % 2 !== 0}
                />
              )
            )}
            {isCheck.length > 0 && <div style={{ height: "55px" }}></div>}
          </TableBody>
        </>
      )}
      {screenWidth < 576 && (
        <TableBody onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
          {(currentItems.length ? currentItems : sortTableData)?.map(
            (row, index) => (
              <MobileTableRows
                rawData={rawData}
                tableFields={tableFields}
                info={row}
                key={row.taskId}
                index={index}
                id={isCheckAll ? index : row.taskId}
                isMouseOver={isMouseOver}
                isMouseOverScroll={isMouseOverScroll}
                keyRow={row}
                isCheck={isCheck}
                isCheckAll={isCheckAll}
                handleClick={handleClick}
                handleRemove={handleRemove}
                isOddNumber={index % 2 !== 0}
              />
            )
          )}
          <div style={{ height: "20px" }}></div>
          {isCheck.length > 0 && <div style={{ height: "55px" }}></div>}
        </TableBody>
      )}
      {isCheck.length > 0 && (
        <AuthGuard module={'assessment'} permission={'delete'}>
          <Table className="table-ui__pagination-wrapper-delete">
          <TableCell style={{ borderBottom: "none", padding: "0px" }}>
          <p className="table-ui__results-article-delete">
              <span>{isCheck.length} selected</span>{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={handleRemoveChecked}>
                <img src={remove} alt="remove" className="table-ui__row-icon" />
              </button>
              <span style={{ paddingLeft: "10px" }}>Delete</span>
            </p>
          </TableCell>
          <TableCell
            style={{ borderBottom: "none", fontFamily: "Avenir LT Std" }}
            className="table-ui__pagination-wrapper-content-delete"
          >
            <button
              onClick={handleSelectAllItems}
              className="table-ui__pagination-wrapper-dropdown"
            >
              <p className="table-ui__pagination-wrapper-select">
                {screenWidth < 576
                  ? "Select all"
                  : `Select all ${data.length} assessments`}
              </p>
            </button>
          </TableCell>
        </Table>
        </AuthGuard>
      )}
      <Table className="table-ui__pagination-wrapper">
        <TableCell style={{ borderBottom: "none", padding: "0px" }}>
          <p className="table-ui__results-article">
            Showing results {itemsRange.start}-{itemsRange.end} of {data.length}
          </p>
        </TableCell>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        {screenWidth > 576 && (
          <TableCell
            style={{ borderBottom: "none", display: "flex", padding: "0px" }}
            className="table-ui__pagination-wrapper-dropdown"
          >
            <span className="table-ui__pagination-wrapper-dropdown-left">
              show
            </span>
            <Dropdown items={items} width={64} setValue={setValue} />
            <span className="table-ui__pagination-wrapper-dropdown-right">
              {" "}
              on page
            </span>
          </TableCell>
        )}
      </Table>
    </StyledTable>
  );
};

export default TableList;
