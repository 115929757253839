import CodingSelect from "../_elements/CodingSelect";
import {
  addDatabase,
  setDatabase,
} from "../../store/assessmentTask/assessmentTask.actions";
import React, { useEffect, useState } from "react";
import { validateFormField } from "../../config/utils";
import { useDispatch } from "react-redux";
import fetchData from "../../hook/fetchData";
import postData from "../../hook/postData";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

const cross = require("../../images/icon/cross.svg");

function DialogDatabase({ onDialog, homeQuestion }) {
  const [validFieldsButtonEvent, setValidFieldsButtonEvent] = useState(false);
  const [validFields, setValidFields] = useState(false);
  const [validFieldsTitle, setValidFieldsTitle] = useState(false);
  const [validFieldScript, setValidFieldsScript] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchData(
      `${API_ASSESSMENT}/api/live-coding/packages`,
      setPackageOptions
    )
      .then((res) => res.data)
  }, []);
    
  const onSave = () => {
    validFields &&
      dispatch(
        addDatabase({
          ...homeQuestion.database,
          questionId: homeQuestion.id,
        })
      );

    postData(`${API_ASSESSMENT}/api/assesment-database`,{
      title:homeQuestion.database.title,
      description:homeQuestion.database.description?homeQuestion.database.description : "",
      script:homeQuestion.database.script,
      packageId:homeQuestion.database.packages.id ?homeQuestion.database.packages.id:null,
    })
  }

  useEffect(() => {
    setValidFields(
      !(
        homeQuestion.database.title === "" ||
        homeQuestion.database.script === ""
      ) &&
        !(
          homeQuestion.database.title === null ||
          homeQuestion.database.script === null
        )
    );
  }, [homeQuestion.database.title, homeQuestion.database.script]);

  return (
    <div className="database-dialog" onClick={() => onDialog(false)}>
      <div
        className="database-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="database-dialog__head">
          <h2 className="database-dialog__head__title">
            Create a New Database
          </h2>
          <button
            className="database-dialog__head__cross-ico"
            onClick={() => {
              onDialog(false);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="two-input-task">
          <div className="two-input-task-select-bar">
            <label className="label-task-font" htmlFor="task-home-label">
              <span>
                Title of Database <span className="asterisk-sign">*</span>
              </span>
            </label>
            <input
              className={`live-task-input ${
                validateFormField(
                  homeQuestion?.database?.title,
                  validFieldsTitle,
                  validFieldsButtonEvent
                ) && "error"
              }`}
              type="text"
              value={homeQuestion?.database?.title}
              placeholder="Enter database’s title"
              onChange={(e) => {
                dispatch(
                  setDatabase({
                    ...homeQuestion.database,
                    questionId: homeQuestion.id,
                    title: e.target.value,
                  })
                );
                setValidFieldsTitle(
                  e.target.value === "" || homeQuestion.database.title === null
                );
              }}
            />
            {validateFormField(
              homeQuestion?.database?.title,
              validFieldsTitle,
              validFieldsButtonEvent
            ) && <div className="error-data-one">Invalid data</div>}
          </div>
          <div className="two-input-task-select-bar">
            <label htmlFor="live-task-label" className="label-task-font">
              Packages
            </label>
            <CodingSelect
              placeholder="Select packages"
              options={packageOptions}
              selected={homeQuestion?.database?.packages}
              setSelected={(activePackage) =>
                dispatch(
                  setDatabase({
                    ...homeQuestion.database,
                    questionId: homeQuestion.id,
                    packages: activePackage,
                  })
                )
              }
            />
          </div>
        </div>
        <label htmlFor="live-task-label" className="label-description-font">
          Database Description
        </label>
        <textarea
          value={homeQuestion?.database?.description}
          rows={4}
          className="live-task-input-textarea"
          placeholder="Write database description"
          onChange={(e) => {
            dispatch(
              setDatabase({
                ...homeQuestion.database,
                questionId: homeQuestion.id,
                description: e.target.value,
              })
            );
          }}
        />
        <label htmlFor="live-task-label" className="label-description-font">
          Script <span className="asterisk-sign">*</span>
        </label>
        <textarea
          value={homeQuestion?.database?.script}
          rows={4}
          className={`live-task-input-textarea ${
            validateFormField(
              homeQuestion?.database?.script,
              validFieldScript,
              validFieldsButtonEvent
            ) && "error"
          }`}
          placeholder="Write database description"
          onChange={(e) => {
            dispatch(
              setDatabase({
                ...homeQuestion.database,
                questionId: homeQuestion.id,
                script: e.target.value,
              })
            );
            setValidFieldsScript(
              e.target.value === "" || homeQuestion.database.script === null
            );
          }}
        />
        {validateFormField(
          homeQuestion?.database?.script,
          validFieldScript,
          validFieldsButtonEvent
        ) && <div className="error-data-one">Invalid data</div>}
        <div className="database-dialog__footer">
          <div
            className="database-dialog__footer__container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              className="database-dialog__footer__container__cancel"
              onClick={() => onDialog(false)}
            >
              Cancel
            </button>
            <button
              className="database-dialog__footer__container__create"
              onClick={(e) => {
                onDialog(false);
                onSave()
                setValidFieldsButtonEvent(true);
                e.preventDefault();
              }}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DialogDatabase;
