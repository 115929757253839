import React from "react";
import { CheckboxButton } from "../CheckboxButton";
const iconSearchGray = require("../../images/iconSearchGray.svg");

const TabCreatedBy = ({
  handleCheckCreatedBy,
  isCheckCreatedBy,
  filteredName,
  handleCheckCreatedCheckbox,
  isCheck,
}) => {
  return (
    <>
      <div className="search-filter-input">
        <button className="search-filter-input__search-button">
          <img
            src={iconSearchGray}
            alt="search-icon"
            className="search-filter-input__icon"
          />
        </button>
        <input
          type="search"
          placeholder="Search name"
          className="search-filter-input__input"
          onChange={handleCheckCreatedBy}
          value={isCheckCreatedBy ? isCheckCreatedBy : []}
          onKeyDown={(e) => e.key === "Enter"}
        />
      </div>
      <div className="gradient"></div>
      <div
        className="language-filter-container"
        style={{ overflowY: "scroll" }}
      >
        {filteredName.map((item, id) => (
          <div
            style={{ marginTop: "5px", marginBottom: "5px" }}
            key={id}
          >
            <CheckboxButton
              style={{ paddingRight: "25px" }}
              key={id}
              type="checkbox"
              name={item}
              id={id}
              handleClick={handleCheckCreatedCheckbox}
              isChecked={isCheck.includes(id.toString())}
            />
            <span style={{ paddingLeft: "15px", fontSize: "14px" }}>
              {item}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default React.memo(TabCreatedBy);
