import { CodingAssessments } from "../components/CodingAssessments";
import {
  LocationProvider,
  createHistory
} from "@reach/router";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import "../styles/main.scss";
import LiveTask from "../components/tasks/LiveTask";
import HomeTask from "../components/tasks/HomeTask";
import { Provider } from "react-redux";
import store from "../config/store";

import { permissionRoutesType, useHasPermission, UnAuthorizePageComponent, NotFoundPageComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const routes: permissionRoutesType[] = [
  {
    path: "/coding-assessments",
    element: <CodingAssessments />,
    module: "assessment",
    permissions: ["view","add"]
  },
  {
    path: "/coding-assessments/live-task",
    element: <LiveTask />,
    module: "assessment",
    permissions: "add"
  },
  {
    path: "/coding-assessments/live-task/:id",
    element: <LiveTask />,
    module: "assessment",
    permissions: "edit"
  },
  {
    path: "/coding-assessments/take-home-task",
    element: <HomeTask />,
    module: "assessment",
    permissions: "add"
  },
  {
    path: "/coding-assessments/take-home-task/:id",
    element: <HomeTask />,
    module: "assessment",
    permissions: "edit"
  }
]
export default function Root(props) {
  const history = createHistory(window);
  const {onAuthorizedRoutes} = useHasPermission();
  return (
    <>
      <LocationProvider history={history}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              {routes.map(route => {
                return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent/>} />
              })}
                <Route path='*' element={onAuthorizedRoutes(routes[0].module, routes[0].permissions) ? <Navigate to={routes[0].path}/> : <NotFoundPageComponent/>} />
            </Routes>
          </BrowserRouter>
        </Provider>
      </LocationProvider>
    </>
  );
}