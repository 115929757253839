import React, { useRef, useState } from "react";
import useOnClickOutside from "../../hook/common/use-outside-click";
import cn from "classnames";

export interface Option {
  id: number;
  name?: string;
  data?: any;
  label?: string;
  title?: string;
}

interface CustomSelectProps {
  filterOptions?: boolean;
  options: Option[];
  placeholder?: string;
  selected: Option | null; // Allow null for deselected state
  setSelected?: React.Dispatch<React.SetStateAction<Option | null>>; // Update the type to handle null value
  label?: string;
  className?: string;
  noBorder?: boolean;
  validate?: boolean;
}

const CodingSelect = ({
  filterOptions,
  options,
  placeholder,
  selected,
  setSelected,
  label,
  className,
  validate,
}: CustomSelectProps) => {
  const dropdownIcon = require("./../../images/dropDownIconCommon.svg");
  const dropdownIconSelected = require("./../../images/dropDownSelected.svg");

  const ref = useRef(null);
  const [active, setActive] = useState(false);

  const selectItem = (i) => {
    if (i === selected) {
      setSelected(null);
    } else {
      setSelected(i);
    }
    setActive(false);
  };

  useOnClickOutside(ref, () => setActive(false));

  const finalClassName = [`Select`];
  if (className) finalClassName.push(className);

  const options_array = options?.map((item, key) => (
    <div
      key={key}
      className="customSelect__select_box__options_container__option"
      onClick={() => selectItem(item)}
    >
      <input
        type="radio"
        className="customSelect__select_box__options_container__option__radio"
        id={item?.id?.toString() || key.toString()}
        name="category"
      />
      <label htmlFor={item.name || item.title || item.label}>
        {item.name ? item.name || item.title : item.label || item.title}
      </label>
    </div>
  ));

  function openSelect() {
    if (!active && !selected) {
      setSelected(null);
    }
    setActive(!active);
  }

  return (
    <div ref={ref} className={finalClassName.join(" ")}>
      <label className="customSelect__label">{label}</label>
      <div className={cn("customSelect", { active: active })}>
        <div className={cn("customSelect__select_box", { "filter-options-background": filterOptions })}>
          {active && (
            <div className="customSelect__optionsWrapper">
              <div className={cn("customSelect__select_box__options_container scroll active", { "filter-scroll": filterOptions })}>
                {options_array}
              </div>
            </div>
          )}
          <div
            className={cn(
              "customSelect__selected",
              { active: active },
              { error: validate },
              { "filter-options": filterOptions }
            )}
            style={{
              backgroundImage: active
              ? `url(${dropdownIconSelected})`
              : `url(${dropdownIcon})`,
              color:
              selected?.name || selected?.title || selected?.label || selected
              ? ""
              : "#999EA5",
              }}
              onClick={openSelect}
              >
              {selected
              ? selected?.title || selected?.name || selected?.label
              : placeholder}
              {typeof selected === "string" ? selected : ""}
              </div>
              {validate && <div className="error-data-select">Invalid data</div>}
              </div>
              </div>
              </div>
              );
              }
              
              export default CodingSelect;
