import React from "react";

const TabPackages = ({
  questionPackType,
  handlePackChange,
  setQuestionPackType,
}) => {
  return (
    <>
      <div className="filter-package" style={{ display: "inline-flex" }}>
        <label>
          <input
            type="radio"
            value="mysql"
            name="jdbc-pack"
            checked={questionPackType === "mysql"}
            onChange={handlePackChange}
          />
          <span className="filter-button-package">JDBC (My SQL)</span>
        </label>
        <label>
          <input
            type="radio"
            value="postgresql"
            name="jdbc-pack"
            checked={questionPackType === "postgresql"}
            onChange={(e) => {
              setQuestionPackType("postgresql");
            }}
          />
          <span className="filter-button-package">
            JDBC (Postgress)
          </span>
        </label>
      </div>
    </>
  );
};

export default React.memo(TabPackages);
