import React, { useCallback, useEffect, useMemo, useState } from "react";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import {
  LanguagesSet,
  packageSelect,
  requestSearchUrl,
} from "../../config/utils";
import TabQuestionType from "./TabQuestionType";
import TabLanguages from "./TabLanguages";
import TabPackages from "./TabPackages";
import TabCreatedBy from "./TabCreatedBy";
import TabCreatedOn from "./TabCreatedOn";
import fetchData from "../../hook/fetchData";
import { Portal } from "../../hook/portal";
import CodingSelect from "../../components/_elements/CodingSelect";
import { setFilteredData } from "../../store/assessmentTask/assessmentTask.actions";
import { useDispatch } from "react-redux";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

const resetButton = require("../../images/resetButton.svg");
const saveFilterButton = require("../../images/saveFilterButton.svg");
const filterSm = require("../../images/filterSm.svg");
const cross = require("../../images/icon/cross.svg");

const menuOption: any = [
  { value: "questionsType", label: "Questions Type" },
  { value: "languages", label: "Languages" },
  { value: "packages", label: "Packages" },
  { value: "createdBy", label: "Created by" },
  { value: "createdOn", label: "Created on" },
];

const filteredName = [
  "name1",
  "name2",
  "name3",
  "name4",
  "name5",
  "name6",
  "name7",
];

const BootstrapInputFilter = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    width: "100%",
    padding: "6px 26px 6px 12px",
    fontFamily: [
      "Avenir",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      color: "#2A2C33",
    },
  },
  "&.Mui-focused": {
    borderRadius: 4,
    color: "#2A2C33",
    backgroundColor: "#ffffff",
    border: "1px solid #099C73",
    "& .MuiSvgIcon-root": {
      color: "#099C73",
    },
  },
}));

const DropdownFilter = ({ items }) => {
  const [issuedOnDate, setIssuedOnDate] = useState(null);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState<any>("Questions Type");
  const [checkedTaskType, setCheckedTaskType] = useState("");
  const [questionPackType, setQuestionPackType] = useState("");
  const [isCheckCreatedBy, setIsCheckCreatedBy] = useState([]);
  const [isLanguage, setIsLanguage] = useState([]);
  const [data, setData] = useState(null);

  const [isCheck, setIsCheck] = useState([]);
  const [isCheckLanguage, setIsCheckLanguage] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [filteredPath, setFilteredPath] = useState("");

  const languages = LanguagesSet.map((item) => item.name);
  const dispatch = useDispatch();

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenWidth(window.innerWidth);
    };
    addEventListener("resize", updateScreenSize);
    return () => {
      removeEventListener("resize", updateScreenSize);
    };
  }, []);

  let monthNameSt = new Date().toLocaleDateString("en-us", { month: "short" });

  const langSet = useMemo(() => {
    return isLanguage.map((item) => {
        return LanguagesSet.map((i) => (i.name === item ? i.id : "")).filter(
          (r) => r
        );
      })
      .filter((r) => r);
  }, [isLanguage]);

  const setStates = useCallback(() => {
    setIssuedOnDate(items.issuedOn ? new Date(items.issuedOn) : null);
  }, [items.issuedOn]);

  useEffect(() => {
    if (items) {
      setStates();
    }
  }, [items, setStates]);

  // All data in form
  const handleChangeByFilter = useCallback(
    (e) => {
      const createUrl = {
        language: langSet.join(",").toLowerCase(),
        package: questionPackType === "mysql" ? 2 :questionPackType==="postgresql"? 1 :'',
        type: checkedTaskType.toLowerCase(),
        updateBy: isCheckCreatedBy.join(",").toLowerCase(),
        createdOn: issuedOnDate ? issuedOnDate?.getTime() / 1000 : null,
      };

      fetchData(
        requestSearchUrl(
          `${API_ASSESSMENT}/api/coding-assessment/search`,
          createUrl
        ),
        setData
      ).then((res) => {
        const paths = res.config.url.split("/");
        const lastPath = paths[paths.length - 1];
        setFilteredPath(lastPath);
        return dispatch(setFilteredData(res.data));
      });
    },
    [
      filteredPath,
      checkedTaskType,
      data,
      dispatch,
      isCheckCreatedBy,
      issuedOnDate,
      langSet,
      questionPackType,
    ]
  );

  const selectCurrentFilter = (e) => {
    const myValue = e.target.innerText;

    return setCurrentFilter(myValue);
  };

  // Change type task
  const onChangeTypeTask = (e) => {
    setCheckedTaskType(e.target.value);
  };

  // Change pack
  const handlePackChange = (e) => {
    setQuestionPackType(e.target.value);
  };

  const openDatePicker = useCallback(() => {
    setDatePickerIsOpen(!datePickerIsOpen);
  }, [datePickerIsOpen]);

  // Language checkbox
  const handleClickLanguage = useCallback(
    (event) => {
      const {
        target: { name, id, value },
      } = event;
      if (isLanguage.length === 3) return;
      setIsLanguage(
        typeof value === "string" && name
          ? [...isLanguage.filter((item) => item !== name || item !== ""), name]
          : value.split(",")
      );
      setIsCheckLanguage([...isCheckLanguage, id.toString()]);
    },
    [isCheckLanguage, isLanguage]
  );

  const handleCheckLanguageCheckbox = useCallback(
    (event) => {
      const {
        target: { name, id, checked },
      } = event;
      setIsCheckLanguage([...isCheck, id.toString()]);
      if (!checked) {
        setIsCheckLanguage(isCheckLanguage.filter((item) => item !== id));
        setIsLanguage(isLanguage?.filter((item) => item !== name));
      }
      checked && handleClickLanguage(event);
    },
    [handleClickLanguage, isCheck, isCheckLanguage, isLanguage]
  );

  // Created By checkbox
  const handleCheckCreatedBy = useCallback(
    (event) => {
      const {
        target: { name, id, value },
      } = event;
      setIsCheckCreatedBy(
        typeof value === "string" && name
          ? [
              ...isCheckCreatedBy.filter(
                (item) => item !== name || item !== ""
              ),
              name,
            ]
          : value.split(",")
      );
      setIsCheck([...isCheck, id.toString()]);
    },
    [isCheck, isCheckCreatedBy]
  );

  const handleCheckCreatedCheckbox = useCallback(
    (event) => {
      const {
        target: { name, id, checked },
      } = event;
      setIsCheck([...isCheck, id.toString()]);
      if (!checked) {
        setIsCheck(isCheck.filter((item) => item !== id));
        setIsCheckCreatedBy(isCheckCreatedBy?.filter((item) => item !== name));
      }
      checked && handleCheckCreatedBy(event);
    },
    [handleCheckCreatedBy, isCheck, isCheckCreatedBy]
  );

  const handleResetSection = () => {
    if (currentFilter.includes("Questions Type") && checkedTaskType !== "") {
      setCheckedTaskType("");
    }
    if (currentFilter.includes("Languages") && isLanguage.length > 0) {
      setIsLanguage([]);
      setIsCheckLanguage([]);
    }
    if (
      currentFilter.includes("Packages") &&
      questionPackType !== "jdbc-mysql-pack"
    ) {
      setQuestionPackType("jdbc-mysql-pack");
    }
    if (currentFilter.includes("Created by") && isCheckCreatedBy.length > 0) {
      setIsCheckCreatedBy([]);
      setIsCheck([]);
    }
    if (currentFilter.includes("Created on") && issuedOnDate !== null) {
      setIssuedOnDate(null);
    }
  };

  const handleResetAllSection = () => {
    setCheckedTaskType("");
    setIsLanguage([]);
    setIsCheckLanguage([]);
    setQuestionPackType("jdbc-mysql-pack");
    setIsCheckCreatedBy([]);
    setIsCheck([]);
    setIssuedOnDate(null);
  };

  const renderDropdownFilter = useMemo(() => {
    switch (currentFilter) {
      case "Questions Type":
        return {
          body: (
            <TabQuestionType
              onChangeTypeTask={onChangeTypeTask}
              checkedTaskType={checkedTaskType}
            />
          ),
        };
      case "Languages":
        return {
          body: (
            <TabLanguages
              handleClickLanguage={handleClickLanguage}
              isLanguage={isLanguage}
              handleCheckLanguageCheckbox={handleCheckLanguageCheckbox}
              isCheckLanguage={isCheckLanguage}
              languages={languages}
            />
          ),
        };
      case "Packages":
        return {
          body: (
            <TabPackages
              questionPackType={questionPackType}
              handlePackChange={handlePackChange}
              setQuestionPackType={setQuestionPackType}
            />
          ),
        };
      case "Created by":
        return {
          body: (
            <TabCreatedBy
              handleCheckCreatedBy={handleCheckCreatedBy}
              isCheckCreatedBy={isCheckCreatedBy}
              filteredName={filteredName}
              handleCheckCreatedCheckbox={handleCheckCreatedCheckbox}
              isCheck={isCheck}
            />
          ),
        };
      case "Created on":
        return {
          body: (
            <TabCreatedOn
              issuedOnDate={issuedOnDate}
              datePickerIsOpen={datePickerIsOpen}
              openDatePicker={openDatePicker}
              setIssuedOnDate={setIssuedOnDate}
              monthNameSt={monthNameSt}
            />
          ),
        };
      default:
        return {
          body: <div></div>,
        };
    }
  }, [
    currentFilter,
    checkedTaskType,
    handleClickLanguage,
    isLanguage,
    handleCheckLanguageCheckbox,
    isCheckLanguage,
    languages,
    questionPackType,
    handleCheckCreatedBy,
    isCheckCreatedBy,
    handleCheckCreatedCheckbox,
    isCheck,
    issuedOnDate,
    datePickerIsOpen,
    openDatePicker,
    monthNameSt,
  ]);

  return (
    <div className="coding-assessments__dropdown">
      <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
        <div className="dropdown-filer-container">
          {screenWidth > 576 && (
            <Select
              style={{ width: "100%" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                color: "#999EA5",
              }}
              displayEmpty
              id="demo-customized-select-native"
              value={["Filter by"]}
              // defaultOpen={true}
              input={<BootstrapInputFilter />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Filter by</em>;
                }
                return selected;
              }}
            >
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  width: "575px",
                }}
              >
                <div
                  style={{
                    padding: "21px 21px 21px 0",
                  }}
                >
                  <button
                    onClick={handleResetAllSection}
                    style={{ marginBottom: "20px" }}
                  >
                    <img
                      style={{ width: "16px", marginBottom: "3px" }}
                      src={resetButton}
                      alt="reset"
                    />
                    &nbsp;&nbsp;
                    <span style={{ color: "#099C73" }}>Reset all</span>
                  </button>
                  <div
                    className="dropdown-filer-container"
                    style={{ width: "157px" }}
                  >
                    {items.map((item, id) => (
                      <MenuItem
                        data-my-value={item}
                        onClick={(e) => selectCurrentFilter(e)}
                        defaultValue={currentFilter}
                        value={item}
                        key={id}
                        selected={item === currentFilter}
                        className="dropdown-filer-container__menu-item"
                        sx={{
                          "&.Mui-selected": {
                            fontWeight: 500,
                            color: "#099C73",
                            backgroundColor: "rgba(172, 216, 209, 0.3)",
                            borderLeft: "2px solid #099C73",
                            borderRadius: "3px",
                          },
                        }}
                      >
                        <div className="menu-item-filter-dropdown">{item}</div>
                      </MenuItem>
                    ))}
                  </div>
                </div>
                <div className="vertical-line"></div>
                <div className="filter-dropdown-content-container">
                  <div className="filter-dropdown-content-buttons">
                    <button value={currentFilter} onClick={handleResetSection}>
                      <img
                        style={{ width: "16px", marginBottom: "3px" }}
                        src={resetButton}
                        alt="reset section"
                      />
                      &nbsp;&nbsp;
                      <span style={{ color: "#099C73" }}>Reset section</span>
                    </button>
                    <button
                      value={currentFilter}
                      onClick={handleChangeByFilter}
                    >
                      <img
                        style={{ width: "16px", marginBottom: "3px" }}
                        src={saveFilterButton}
                        alt="save"
                      />
                      &nbsp;&nbsp;
                      <span style={{ color: "#099C73" }}>Save</span>
                    </button>
                  </div>
                  <div className="filter-dropdown-content">
                    {renderDropdownFilter.body}
                  </div>
                </div>
              </div>
            </Select>
          )}
          {screenWidth < 576 && (
            <section>
              <button onClick={() => setShowModal(true)}>
                <div className="search-filter-input__icon">
                  <img src={filterSm} alt="mobile size phone filter" />
                </div>
                <div className="search-filter-input__filter-title">Filter</div>
              </button>
              {showModal && (
                <Portal>
                  <div className="modal">
                    <div className="modal__container">
                      <div className="modal__header-container">
                        <div className="modal__filter-title">Filter by</div>
                        <div>
                          <button
                            className="modal__cross-ico"
                            onClick={() => setShowModal(false)}
                          >
                            <img src={cross} alt="cross-icon" />
                          </button>
                        </div>
                      </div>
                      <div className="modal__mobile-menu">
                        <CodingSelect
                          selected={currentFilter}
                          options={menuOption}
                          setSelected={(e) => selectCurrentFilter(e)}
                          filterOptions={true}
                        />
                      </div>
                      <div className="filter-dropdown-content">
                        {renderDropdownFilter.body}
                      </div>
                      <div className="modal__reset-section">
                        <button
                          value={currentFilter}
                          onClick={handleResetSection}
                        >
                          <img
                            style={{ width: "16px", marginBottom: "3px" }}
                            src={resetButton}
                            alt="reset section"
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#099C73" }}>
                            Reset section
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="modal__footer-container">
                      <div className="modal__footer-line">
                        <button onClick={handleResetAllSection}>
                          <img
                            style={{ width: "16px", marginBottom: "3px" }}
                            src={resetButton}
                            alt="reset"
                          />
                          &nbsp;&nbsp;
                          <span style={{ color: "#099C73" }}>Reset all</span>
                        </button>
                        <button
                          value={currentFilter}
                          onClick={(e) => {
                            handleChangeByFilter(e);
                            setShowModal(false);
                          }}
                          className="btn btn--green"
                        >
                          Show result
                        </button>
                      </div>
                    </div>
                  </div>
                </Portal>
              )}
            </section>
          )}
        </div>
      </FormControl>
    </div>
  );
};

export default DropdownFilter;
